import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import SimpleBar from 'simplebar-react';
import draweritems from '../constants/draweritems';


function NavGroup(props: { group: any, topDiv?: boolean }) {
    const { group, topDiv } = props;
    return (
        <>
            {topDiv && <Divider sx={{ my: 1 }} />}
            {Boolean(group.name) && <ListSubheader component="div" inset>{group.name}</ListSubheader>}
            {group.items && group.items.map((Item: any, i: number) => {
                return (
                    <ListItemButton key={i} href={`/${Item.slug}`}>
                        <ListItemIcon>
                            <Item.Icon />
                        </ListItemIcon>
                        <ListItemText primary={Item.title} />
                    </ListItemButton>
                )
            })}
        </>
    );
}

export default function NavItems() {
    return (
        <List component="nav" style={{ padding: 0 }}>
            <SimpleBar style={{
                height: "calc( 100vh - 64px - 10px)",
                overflowX: 'hidden',
                marginTop: 8,
            }}>
                {
                    Array.isArray(draweritems) ?
                        draweritems.map((draweritem: any, i) => <NavGroup key={i} group={draweritem} />)
                        :
                        <NavGroup group={draweritems} />
                }
            </SimpleBar>
        </List>
    )
}