
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Copyright from '../parts/Copyright';
import Drawer from "../parts/Drawer";
import AppBar from '../parts/AppBar';
import SimpleBar from 'simplebar-react';
import { useSelector } from 'react-redux';
import { User } from 'firebase/auth';



function AppWrap({ children }: any) {
    const { user } = useSelector(({ auth }: { auth: { user: null | undefined | User } }) => auth)

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar />
            {user? <Drawer /> : null}
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <SimpleBar style={{
                    height: "calc( 100vh - 64px )",
                    marginTop: 64,
                    marginBottom: 0
                }}>
                    <Container
                        maxWidth="lg"
                        style={{
                            minHeight: "calc( 100vh - 129px )",
                            marginTop: 0,
                            marginBottom: 0
                        }}>
                        {children}
                    </Container>
                    <Copyright style={{ height: 64 }} />
                </SimpleBar>
            </Box>
        </Box>
    );
}

export default function Default(props: any) {
    return <AppWrap>{props.children}</AppWrap>
}