import type { Handler } from ".";
import settings from "../constants/settings";
import dotProp from "dot-prop-immutable";

export const namespace: string = 'THEME';

export const init_state = {
    page_loading: true,
    logs: [],
    current_theme: settings.defaultTheme
}

export type logType = 'error' | 'warning' | 'info' | 'success';

var actions: { [key: string]: string } = {
    /**
     * Add your action types here
     */
    SET_MODE: "SET_MODE",
    SWITCH_MODE: "SWITCH_MODE",
    PAGE_LOADING_DONE: "PAGE_LOADING_DONE",
    PAGE_LOADING_START: "PAGE_LOADING_START",
    PAGE_LOADING_ERROR: "PAGE_LOADING_ERROR",
    SET_CURRENT_THEME: "SET_CURRENT_THEME",
    ERROR: "ERROR",
    WARNING: "WARNING",
    INFO: "INFO",
    SUCCESS: "SUCCESS"
}
for (const key in actions) {
    actions[key] = `${namespace}.${actions[key]}`;
}

export const handlers: Handler[] = [
    /**
     * Define a handler for each action type
     *  (A handler defines the logic by which the state is manipulated)
     */
    {
        type: actions.SWITCH_MODE,
        handler: (state: any) => dotProp.set(
            state,
            "current_theme.palette.mode",
            state.current_theme.palette.mode === 'dark' ? 'light' : 'dark'
        )
    },
    {
        type: actions.PAGE_LOADING_DONE,
        handler: (state) => { return { ...state, page_loading: false } }
    },
    {
        type: actions.PAGE_LOADING_START,
        handler: (state) => { return { ...state, page_loading: true } }
    },
    {
        type: actions.PAGE_LOADING_ERROR,
        handler: (state: any, payload) => { return { ...state, page_loading: false, logs: [...state.logs, { ...payload, type: "error" }] } }
    },
    {
        type: actions.SET_CURRENT_THEME,
        handler: (state, payload) => { return { ...state, current_theme: payload } }
    },
    {
        type: actions.ERROR,
        handler: (state: any, payload) => { return { ...state, logs: [...state.logs, { msg: payload, type: "error" }] } }
    },
    {
        type: actions.WARNING,
        handler: (state: any, payload) => { return { ...state, logs: [...state.logs, { msg: payload, type: "warning" }] } }
    },
    {
        type: actions.INFO,
        handler: (state: any, payload) => { return { ...state, logs: [...state.logs, { msg: payload, type: "info" }] } }
    },
    {
        type: actions.SUCCESS,
        handler: (state: any, payload) => { return { ...state, logs: [...state.logs, { msg: payload, type: "success" }] } }
    },
]


/** 
 * Define dispatcher functions for each action type.
 * The dispatcher function is just a wrapper interface for 
 * a handler. It should be the only way used to manipulate the state
 */
var functions: { [key: string]: any } = {
    toggleMode: () => {
        return (dispatch: Function) => {
            dispatch({
                type: actions.SWITCH_MODE,
            })
        }
    },
    setTheme: (currentTheme: object) => {
        return (dispatch: Function) => {
            dispatch({
                type: actions.SET_CURRENT_THEME,
                payload: currentTheme
            })
        }
    },
    pageLoadingError: (msg: string) => {
        return (dispatch: Function) => {
            dispatch({
                type: actions.PAGE_LOADING_ERROR,
                payload: msg
            })
        }
    },
    pageLoadingComplete: () => {
        return (dispatch: Function) => {
            dispatch({
                type: actions.PAGE_LOADING_DONE,
            })
        }
    },
    pageLoadingStart: () => {
        return (dispatch: Function) => {
            dispatch({
                type: actions.PAGE_LOADING_START,
            })
        }
    },
    error: (msg: string) => {
        return (dispatch: Function) => {
            dispatch({
                type: actions.ERROR,
                payload: msg
            })
        }
    },
    warn: (msg: string) => {
        return (dispatch: Function) => {
            dispatch({
                type: actions.WARNING,
                payload: msg
            })
        }
    },
    info: (msg: string) => {
        return (dispatch: Function) => {
            dispatch({
                type: actions.INFO,
                payload: msg
            })
        }
    },
    success: (msg: string) => {
        if(typeof(msg) !== 'string'){
            msg = String(msg);
        }
        return (dispatch: Function) => {
            dispatch({
                type: actions.SUCCESS,
                payload: msg
            })
        }
    },
}
export default functions;