import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import settings from '../constants/settings';
import Logo from '../constants/logo';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import navFunction from "../redux/nav";
import ProfileButton from './ProfileButton';
import Button from '@mui/material/Button';
import { User } from 'firebase/auth';



const drawerWidth: number = settings.navbar.drawerWidth;


interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBarElement = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

export default function AppBar() {
    const { user } = useSelector(({ auth }: { auth: { user: null | undefined | User } }) => auth)
    const { drawer_open } = useSelector(({ nav }: { nav: { drawer_open: boolean } }) => nav);
    const dispatch = useDispatch();
    return (
        <AppBarElement position="absolute" open={Boolean(drawer_open && user)}>
            <Toolbar
                sx={{
                    pr: '24px', // keep right padding when drawer closed
                }}
            >
                {user && <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => dispatch(navFunction.toggleDrawer())}
                    sx={{
                        marginRight: '36px',
                        ...(drawer_open && { display: 'none' }),
                    }}
                >
                    <MenuIcon />
                </IconButton>
                }
                {Logo}
                {user &&
                    <IconButton
                        color="inherit"
                        sx={{
                            mr: '15px',
                            ml: '15px'
                        }}
                    >
                        <Badge badgeContent={4} color="secondary">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>}
                {user ?
                    <ProfileButton
                        sx={{
                            flexGrow: 0
                        }}
                    /> :
                    <Button 
                        color="inherit"
                        onClick={() => window.location.assign(settings.loginURL)}
                    >Login
                    </Button>}
            </Toolbar>
        </AppBarElement>
    )
};