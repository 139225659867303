import type { Handler } from ".";

export const namespace: string = 'NAV';

export const init_state = {
    drawer_open: false
}

var actions: { [key: string]: string } = {
    /**
     * Add your action types here
     */
    TOGGLE_DRAWER: "TOGGLE_DRAWER"
}
for (const key in actions) {
    actions[key] = `${namespace}.${actions[key]}`;
}

export const handlers: Handler[] = [
    /**
     * Define a handler for each action type
     *  (A handler defines the logic by which the state is manipulated)
     */
    {
        type: actions.TOGGLE_DRAWER,
        handler: (state: any) => { return { ...state, drawer_open: !state.drawer_open } }
    }
]


/** 
 * Define dispatcher functions for each action type.
 * The dispatcher function is just a wrapper interface for 
 * a handler. It should be the only way used to manipulate the state
 */
var functions: { [key: string]: any } = {
    toggleDrawer: () => {
        return (dispatch: Function) => {
            dispatch({
                type: actions.TOGGLE_DRAWER,
            })
        }
    }
}
export default functions;