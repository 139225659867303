import { Typography } from "@mui/material";

const Logo = (
    <Typography
        component="h1"
        variant="h6"
        color="inherit"
        noWrap
        sx={{ flexGrow: 1 }}
    >
        Firebase-React
    </Typography>
)

export default Logo