import { useSelector } from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PageLoading from './PageLoading';
import Snackerbars from './Snackerbars';


export default function MandatoryWrapper({ children }: { children: any }) {
    const { page_loading, current_theme } = useSelector(({ theme }: { theme: { mode: any, page_loading: boolean, current_theme: object } }) => theme);
    const theme = createTheme(current_theme);

    return (
        <ThemeProvider theme={theme}>
            {page_loading ? <PageLoading /> : children}
            <Snackerbars />
        </ThemeProvider>
    );
}