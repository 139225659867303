
const settings = {
    privacyPolicyURL: '/privacy',
    termsOfServiceURL: '/tos',
    signInSuccessURL: '/',
    loginURL: '/login',
    copyRightURL: '/',
    websiteName: 'Firebase React Template',
    defaultTheme: {
        // Refer to https://mui.com/material-ui/customization/theming/ for more information
        palette: {
            mode: "dark"
        }
    },
    navbar: {
        drawerWidth: 240,
    }
}

export default settings