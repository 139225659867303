import { Navigate } from "react-router-dom";
import { useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase/firebaseConfig";
import { useDispatch, useSelector } from "react-redux";
import authFunctions from "../redux/auth";
import themeFunctions from "../redux/theme";
import Layouts from "../layouts";
import MandatoryWrapper from "../parts/MandatoryWrapper";


/**
 * Import all pages here 
 */
import About from "./About";
import Login from "./Login";
import Home from "./Home";
import Page404 from "./Page404";
import Terms from "./Terms";
import Privacy from "./Privacy";
import Database from "./Database";


interface PageProps {
    restrincted?: boolean;
    layout?: "default" | "containered" | "blank";
}

function PageWrap(Component: any) {
    return function Page(props: PageProps) {
        const restrincted = props.restrincted ? true : false;
        const layout = props.layout ? props.layout : "default";
        const { user } = useSelector(({ auth }: { auth: { user: object | undefined } }) => auth);
        const dispatch = useDispatch();

        useEffect(() => {
            let authUser = user;
            onAuthStateChanged(auth, (user) => {
                if(authUser === undefined){
                    dispatch(themeFunctions.pageLoadingComplete())
                }
                dispatch(authFunctions.setAuthUser(user))
            });
            // eslint-disable-next-line
        }, []);

        if (restrincted && user === null) {
            return <Navigate to="/login" />
        }
        switch (layout) {
            case "blank":
                return (
                    <MandatoryWrapper>
                        <Component />
                    </MandatoryWrapper>
                )
            case "containered":
                return (
                    <MandatoryWrapper>
                        <Layouts.Containered>
                            <Component />
                        </Layouts.Containered>
                    </MandatoryWrapper>
                )
            default:
                return (
                    <MandatoryWrapper>
                        <Layouts.Default>
                            <Component />
                        </Layouts.Default>
                    </MandatoryWrapper>
                )
        }
    }
}

const Pages = {
    /**
     * Add all pages to this object
     */
    About: PageWrap(About),
    Login: PageWrap(Login),
    Home: PageWrap(Home),
    Page404: PageWrap(Page404),
    Terms: PageWrap(Terms),
    Privacy: PageWrap(Privacy),
    Database: PageWrap(Database),
}

export default Pages;