import MuiDrawer from '@mui/material/Drawer';
import settings from '../constants/settings';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useDispatch, useSelector } from 'react-redux';
import navFunction from "../redux/nav";
import NavItems from "../parts/NavItems";


const drawerWidth: number = settings.navbar.drawerWidth;

const DrawerComponent = styled(MuiDrawer, { shouldForwardProp: (prop: any) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(8),
                },
            }),
        },
    }),
);
export default function Drawer() {
    const { drawer_open } = useSelector(({ nav }: { nav: { drawer_open: boolean } }) => nav);
    const dispatch = useDispatch();
    return (
        <DrawerComponent variant="permanent" open={drawer_open}>
            <Toolbar
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    px: [1],
                }}>
                <IconButton onClick={() => dispatch(navFunction.toggleDrawer())}>
                    <ChevronLeftIcon />
                </IconButton>
            </Toolbar>
            <Divider />
            <NavItems />
        </DrawerComponent>
    )
}