import { configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk';
import { persistReducer } from './redux-persist';

/**
 * Import functions, handlers, and actions from each feature
 */
import * as themeFeature from "./theme";
import * as authFeatures from "./auth";
import * as navFeatures from "./nav";
import * as dbFeatures from "./db";

export type Handler = { type: string, handler: (state: object, payload?: any) => object }
var handlers: { type: string, handler: (state: object, payload?: any) => object }[] = [];
var addedFeatures: { [key: string]: string } = {};

function generateReducer(feature: { default: { [key: string]: Function }, namespace: string, init_state: any, handlers: Handler[] }) {
    const handlersActions = feature.handlers.map(h => h.type);
    // Add handlers to the public handlers list
    handlers = [...handlers, ...feature.handlers];
    // Make sure no namespace conflict
    if (feature.namespace in addedFeatures) {
        throw Error(`Reducer with the same namespace "${feature.namespace}" is already defined`);
    }
    addedFeatures[feature.namespace] = feature.namespace;
    return function reducer(state: any = feature.init_state, action: { type: string, payload?: any }) {
        const i = handlersActions.indexOf(action.type);
        if (i !== -1) {
            return feature.handlers[i].handler(state, action.payload)
        }
        return state;
    }
}



export const apis = {
    /**
     * Add a property for each set of functions of each feature
     */
    theme: themeFeature.default,
    auth: authFeatures.default,
    nav: navFeatures.default,
    db: dbFeatures.default
}


export const store = configureStore({
    reducer: {
        /**
         * Add a property for each feature with a value as
         *   the auto generated reducer returned from the 
         *   generateReducer method.
         */
        theme: persistReducer({key: 'theme', whitelist: ["current_theme.palette.mode"]}, generateReducer(themeFeature)),
        auth: generateReducer(authFeatures),
        nav: persistReducer({key: 'nav', whitelist: ['drawer_open']}, generateReducer(navFeatures)),
        db: generateReducer(dbFeatures)
    },
    middleware: [thunk]
})
