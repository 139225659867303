import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { DocumentList, ListUi, Fields } from "firestore-exoskeleton";
import TextField from "@mui/material/TextField";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { useState } from "react";
import SimpleBar from "simplebar-react";

export type RepresentationMethod =
  | "Table"
  | "ForEach"
  | "DataGrid"
  | "JSONEditor";

export default function Database(props: any) {
  const [collection, setCollection] = useState("collection_meta");
  const [method, setMethod] = useState("JSONEditor" as RepresentationMethod);
  var changeTimeout: undefined | NodeJS.Timeout = undefined;
  const handleCollectionChange = (e: React.ChangeEvent<any>) => {
    console.log("Detected text input... ", e)
    if (changeTimeout) {
      clearTimeout(changeTimeout);
    }
    changeTimeout = setTimeout(() => {
      console.log("Setting Collection to ", e.target.value)
      setCollection(e.target.value);
    }, 1000);
  };
  const Representator = ListUi[method];
  console.log("Rerendering. collection : ", collection, "\n method: ", method)
  return (
    <Grid container spacing={3} mt={2}>
      <Grid item xs={12}>
        <h2>Database Structure</h2>
        <p>
          In this page you can define and manage loosely coupled structure for
          some database collections
        </p>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="collection-name"
                label="Collection"
                defaultValue={collection}
                onChange={handleCollectionChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="method-select-label">
                  Representation Method
                </InputLabel>
                <Select
                  labelId="method-select-label"
                  id="method-select"
                  value={method}
                  label="Representation Method"
                  onChange={(e: SelectChangeEvent) =>
                    setMethod(e.target.value as RepresentationMethod)
                  }
                >
                  {Object.keys(ListUi).map((m) => (
                    <MenuItem key={m} value={m}>
                      {m}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <SimpleBar>
            <div style={{ height: 500 }}>
              <DocumentList 
                collection={collection}
                userCanView={["Their_Own_Content"]}
                liveUpdates
              >
                <Representator>
                  <Fields.TextField path="id" />
                  <Fields.TextField path="collection" />
                </Representator>
              </DocumentList>
            </div>
          </SimpleBar>
        </Paper>
      </Grid>
    </Grid>
  );
}
