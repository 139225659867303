import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Chart from "../parts/Chart";
import Deposits from "../parts/Deposit";
//import Orders from '../parts/Orders';
//import Users from "../parts/Users";
import { DocumentList, ListUi, Fields } from "firestore-exoskeleton";
import { query, collection, orderBy } from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";
import Title from "../parts/Title";
import { store, apis } from "../redux";

const error = (err:any) => store.dispatch(apis.theme.error(String(err)));

export default function Homes(props: any) {
  return (
    <Grid container spacing={3} mt={2}>
      {/* Chart */}
      <Grid item xs={12} md={8} lg={9}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 240,
          }}
        >
          <Chart />
        </Paper>
      </Grid>
      {/* Recent Deposits */}
      <Grid item xs={12} md={4} lg={3}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 240,
          }}
        >
          <Deposits />
        </Paper>
      </Grid>
      {/* Recent Orders */}
      <Grid item sm={12} lg={6}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <Title>Recent Orders</Title>
          <div style={{ height: 600, width: "100%" }}>
            <DocumentList
              db={db}
              collection="order"
              query={query(collection(db, "order"), orderBy("created_at"))}
              onDatabaseError={error}
              liveUpdates
            >
              <ListUi.DataGrid>
                <Fields.DateField path="date" headerName="Date" />
                <Fields.TimeField path="time" headerName="Time" />
                <Fields.DocumentField
                  path="created_by"
                  headerName="Author"
                  headerField="email"
                  collection="users"
                  idField="uid"
                />
              </ListUi.DataGrid>
            </DocumentList>
          </div>
        </Paper>
      </Grid>
      <Grid item sm={12} lg={6}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <Title>Customers</Title>
          <div style={{ height: 600, width: "100%" }}>
            <DocumentList
              collection="user"
              //query={query(collection(db, "order"), orderBy("created_at"))}
              onDatabaseError={error}
              liveUpdates
            >
              <ListUi.DataGrid>
                <Fields.TextField path="name" headerName="Name" width={150} />
                <Fields.TextField
                  path="age"
                  headerName="Age"
                  width={150}
                  MuiTextFieldProps={{
                    type: "number",
                  }}
                />
                <Fields.DocumentField
                  width={150}
                  path="created_by"
                  headerName="Created By"
                  headerField="email"
                  collection="users"
                  idField="uid"
                />
                <Fields.DateTimeField path="created_at" />
              </ListUi.DataGrid>
            </DocumentList>
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
}
