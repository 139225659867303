import type { Handler } from ".";
import { store } from ".";
import dotProp from "dot-prop-immutable";
import {
    collection as Collection,
    addDoc,
    setDoc,
    query,
    where,
    getDocs,
    deleteDoc,
    serverTimestamp,
    DocumentData,
    Query,
    onSnapshot,
    doc,
    orderBy,
    Timestamp
} from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";
import { v4 as uuidv4 } from 'uuid';
import themeFunctions from "./theme";

export const namespace: string = 'DB';

export const init_state = {
}


const TEMP_ID_PREFIX = '__temp-';
export const TEMP_PROP_NAME = '_temp';


var actions: { [key: string]: string } = {
    /**
     * Add your action types here
     */
    MOUNT_OBJECTS: "MOUNT_OBJECTS",
    UNMOUNT_OBJECTS: "UNMOUNT_OBJECTS",
    MOUNT_OBJECTS_COMPLETED: "MOUNT_OBJECTS_COMPLETED",
    MOUNT_OBJECTS_SUBSCRIPTION_ADDED: "MOUNT_OBJECTS_SUBSCRIPTION_ADDED",
    MOUNT_OBJECTS_SUBSCRIPTION_REMOVED: "MOUNT_OBJECTS_SUBSCRIPTION_REMOVED",
    MOUNT_OBJECTS_UPDATED: "MOUNT_OBJECTS_UPDATED",
    MOUNT_OBJECTS_ABORTED: "MOUNT_OBJECTS_ABORTED",
    REMOVE_OBJECT: "REMOVE_OBJECT",
    REMOVE_OBJECT_CONFIRMED: "REMOVE_OBJECT_CONFIRMED",
    REMOVE_OBJECT_FAILED: "REMOVE_OBJECT_FAILED",
    SAVE_OBJECT: "SAVE_OBJECT",
    SAVE_OBJECT_CONFIRMED: "SAVE_OBJECT_CONFIRMED",
    SAVE_OBJECT_FAILED: "SAVE_OBJECT_FAILED",
    ADD_OBJECT: "ADD_OBJECT",
    ADD_LOCAL_OBJECT: "ADD_LOCAL_OBJECT",
    SAVE_LOCAL_OBJECT: "SAVE_LOCAL_OBJECT",
    REMOVE_LOCAL_OBJECT: "REMOVE_LOCAL_OBJECT",
    ADD_OBJECT_CONFIRMED: "ADD_OBJECT_CONFIRMED",
    ADD_OBJECT_FAILED: "ADD_OBJECT_FAILED",
}
for (const key in actions) {
    actions[key] = `${namespace}.${actions[key]}`;
}

export const handlers: Handler[] = [
    /**
     * Define a handler for each action type
     *  (A handler defines the logic by which the state is manipulated)
     */
    {
        type: actions.MOUNT_OBJECTS,
        handler: (state: any, payload) => {
            const { path, collection, subscriptionid } = payload;
            return dotProp.set(state, path, {
                collection,
                subscriptionid
            })
        }
    },
    {
        type: actions.UNMOUNT_OBJECTS,
        handler: (state: any, payload) => {
            const { path } = payload;
            return dotProp.delete(state, path)
        }
    },
    {
        type: actions.MOUNT_OBJECTS_COMPLETED,
        handler: (state: any, payload) => {
            const { path, objects } = payload;
            return dotProp.set(state, `${path}.objects`, objects)
        }
    },
    {
        type: actions.MOUNT_OBJECTS_UPDATED,
        handler: (state: any, payload) => {
            const { path, objects } = payload;
            return dotProp.set(state, `${path}.objects`, [
                ...objects,
                //...state[path].objects.filter((obj: any) => obj[TEMP_PROP_NAME])
            ])
        }
    },
    {
        type: actions.ADD_LOCAL_OBJECT,
        handler: (state: any, payload) => {
            const { path, object } = payload;
            return dotProp.set(state, `${path}.objects`, [...state[path].objects, object])
        }
    },
    {
        type: actions.REMOVE_LOCAL_OBJECT,
        handler: (state: any, payload) => {
            const { path, tempid } = payload;
            return dotProp.set(state, `${path}.objects`, state[path].objects.filter((obj: any) => obj[TEMP_PROP_NAME] !== tempid))
        }
    },
    {
        type: actions.MOUNT_OBJECTS_FAILED,
        handler: (state: any, payload) => {
            const { path } = payload;
            return dotProp.delete(state, path)
        }
    }
]


/** 
 * Define dispatcher functions for each action type.
 * The dispatcher function is just a wrapper interface for 
 * a handler. It should be the only way used to manipulate the state
 */
const functions = {
    /**
     *  Mounts realtime object list at store.getState().db[path].objects
     *    The list of objects in the redux store is automatically updated once any
     *    data changes in the server-side.
     * 
     *  Note: Multiple components might call mountObjects with the same path, and for performance, 
     *    mountObjects will mount the object list only once and will not unmount it untill all subscribed
     *    components requests an unmount using unmountObjects
     * 
     * @param {string} collection The name of the firestore collection from which objects will be mounted.
     * @param {string=} [path=collection] The location under the db store on which objects will be mounted.
     * @param {string=} [_query=] Optionally a custom firestore query to filter and sort the collection. 
     *    the default is a query to retrive all objectes craeted by the currently authenticated user and
     *    sorted by time created.
     * @returns {string} mountId. must be saved and passed to unmountObject when the mounted objects 
     *    are no longer longer needed.
     */
    mountObjects: (collection: string, path: string = collection, _query: Query<DocumentData> | null = null) => {
        const uid = dotProp.get(store.getState(), 'auth.user.uid', null);
        var q = _query || query(
            Collection(db, collection),
            where("created_by", "==", uid),
            orderBy('created_at')
        );

        const unmount = onSnapshot(q, (querySnapshot) => {
            var objects: any[] = []
            querySnapshot.forEach((doc: any) => {
                objects.push({
                    ...doc.data(), id: doc.id, saved: !doc.metadata.hasPendingWrites
                })
            });
            store.dispatch({
                type: actions.MOUNT_OBJECTS_UPDATED,
                payload: { collection, path, objects }
            })
        });
        const subscriptionid = uuidv4();
        if ((window as any).firestoreMounts === undefined) {
            (window as any).firestoreMounts = {}
        }
        if ((window as any).firestoreMounts.hasOwnProperty(path)) {
            // mount already initiated from a previous call
            (window as any).firestoreMounts[path].subscriptions[subscriptionid] = true;
            store.dispatch({
                type: actions.MOUNT_OBJECTS_SUBSCRIPTION_ADDED,
                payload: { collection, path, subscriptionid }
            });
            return subscriptionid;
        }
        else {
            // initiate mount
            var subscriptions: { [key: string]: boolean } = {};
            subscriptions[subscriptionid] = true;
            (window as any).firestoreMounts[path] = { unmount, subscriptions };
        }
        getDocs(q).then((Snapshot: any) => {
            var objects: any[] = []
            Snapshot.forEach((doc: any) => {
                objects.push({
                    ...doc.data(), id: doc.id, saved: true
                })
            });
            store.dispatch({
                type: actions.MOUNT_OBJECTS_COMPLETED,
                payload: { collection, path, objects }
            })
        }, err => {
            store.dispatch(themeFunctions.error(String(err)))
            store.dispatch({
                type: actions.MOUNT_OBJECTS_FAILED,
                payload: { collection, path, err }
            })
        });
        store.dispatch({
            type: actions.MOUNT_OBJECTS,
            payload: { collection, path, subscriptionid }
        });
        return subscriptionid;
    },
    /**
     * Clear the subscription for a document list mount and unmount the
     *   object list if no more subsciptions are left.
     * 
     * @param {string} collection firestore collection name.
     * @param {string} subscriptionid the subscription id returned from mountObjects.
     * @param {string=} [path=collection] path in redux store in which the collection is mounted.
     */
    unmountObjects: (collection: string, subscriptionid: string, path: string = collection) => {
        if (!(window as any).firestoreMounts || !(window as any).firestoreMounts[path]) {
            console.warn(
                'Firestore mount point not found. Did you call unmountObjects before' +
                ' calling mountObjects? Or did you provide a wrong "path" argument?'
            )
            return;
        }
        if (!(window as any).firestoreMounts[path].subscriptions[subscriptionid]) {
            throw new Error(
                'Invalid subsciptionid provided to unmountObjects'
            )
        }
        delete (window as any).firestoreMounts[path].subscriptions[subscriptionid];
        store.dispatch({
            type: actions.MOUNT_OBJECTS_SUBSCRIPTION_REMOVED,
            payload: { collection, path, subscriptionid }
        })
        if (!Object.keys((window as any).firestoreMounts[path].subscriptions).length) {
            const { unmount } = (window as any).firestoreMounts[path];
            unmount();
            delete (window as any).firestoreMounts[path];
            store.dispatch({
                type: actions.UNMOUNT_OBJECTS,
                payload: { collection, path }
            })
        }
    },
    /**
     * Adds a new object to a firestore collection. The reference/path to the object is
     *   automatically generated from the server side. In case you need to set a custom 
     *   docuemnt reference you will have to user saveObject instead.
     * @param {string} collection The name of the firestore collection.
     * @param {any} object The object to add to the collection.
     * @param {string=} [path=collection] Path under the db redux store on which the object will be 
     *   added. Default is same as the collection parameter.
     * @returns {Promise<string>} Returns a promise that resolves to the document reference
     *   of the newly created document in case it was created successfully.
     */
    addObject: async (collection: string, object: object, path: string = collection) => {
        const uid = dotProp.get(store.getState(), 'auth.user.uid', null);
        store.dispatch({
            type: actions.ADD_OBJECT,
            payload: { collection, object, path },
        })
        try {
            const id = await addDoc(Collection(db, collection), {
                ...object,
                created_at: serverTimestamp(),
                created_by: uid
            });
            store.dispatch({
                type: actions.ADD_OBJECT_CONFIRMED,
                payload: id
            });
            return id;
        }
        catch (err) {
            store.dispatch(themeFunctions.error(String(err)))
            store.dispatch({
                type: actions.ADD_OBJECT_FAILED,
                payload: { collection, path, err }
            })
        }
    },
    /**
     * Add an object only to the redux store without saving it to firestore.
     *   used to draft a new object before saving it.
     * @param {string} path The path under the db store in which the object will be saved.
     * @param {object} object the object data.
     * @returns {string} The temp id of the created object.
     */
    addLocalObject: (path: string, object: any) => {
        const tempid = `${TEMP_ID_PREFIX}${uuidv4()}`;
        var localObject = dotProp.set(object, TEMP_PROP_NAME, true);
        localObject[TEMP_PROP_NAME] = tempid
        store.dispatch({
            type: actions.ADD_LOCAL_OBJECT,
            payload: { path, object: localObject }
        })
        return tempid;
    },
    /**
     * Remove a local object from the redux db store. This doesn't affect any data on firestore.
     * @param path The path under the db redux store on which the object resides.
     * @param tempid The tempid assigned to the object.
     */
    removeLocalObject: (path: string, tempid: string) => {
        const [object] = store.getState().db[path].objects.filter((obj: any) => obj[TEMP_PROP_NAME] === tempid);
        if (!object) throw Error(`removeLocalObject failed. Can't local object of tempid "${tempid}" in the redux db store`);
        store.dispatch({
            type: actions.REMOVE_LOCAL_OBJECT,
            payload: { path, tempid }
        })
    },
    /**
     * Attempts to saves a local object to firestore.
     *   if the object being saved has an 'id' property, it will attempt to use it the document reference.
     *   and if an object already existed with the same id, it will attempt to overwrite it. if the id is not
     *   set, it will create a new document object and the id will be automatically generated by firebase.
     * @param collection The firestore collection to which the object should be saved.
     * @param path The path under the db redux store in which the object resides.
     * @param tempid The tempmid of the local object.
     * @returns
     */
    saveLocalObject: async (collection: string, path: string = collection, tempid: string) => {
        store.dispatch({
            type: actions.SAVE_LOCAL_OBJECT,
            payload: { collection, path, tempid }
        })
        const [object] = store.getState().db[path].objects.filter((obj: any) => obj[TEMP_PROP_NAME] === tempid);
        if (!object) throw Error(`saveLocalObject failed. Can't local object of tempid "${tempid}" in the redux db store`);
        var clearObject = dotProp.delete(object, TEMP_PROP_NAME);
        functions.removeLocalObject(path, tempid);
        var objRef = null;
        try {
            if (typeof object.id === 'string' && object.id.length) {
                await functions.saveObject(collection, clearObject);
                objRef = object.id;
                return objRef;
            }
            else {
                objRef = await functions.addObject(collection, clearObject, path);
                return objRef;
            }
        }
        catch (err) {
            functions.addLocalObject(path, object);
            store.dispatch(themeFunctions.error(String(err)))
            store.dispatch({ type: actions.SAVE_OBJECT_FAILED })
        }
    },
    saveAllLocalObjects: async (collection:string, path:string=collection) => {
        const tempObjects = store.getState().db.filter((obj: any) => obj.hasOwnProperty(TEMP_PROP_NAME));
        for(const i in tempObjects){
            const tempid = tempObjects[i][TEMP_PROP_NAME];
            await functions.saveLocalObject(collection, path, tempid);
        }
    },
    /**
     * Saves changes to a firestore document object or create the object if it doesn't exist
     * @param collection the name of the firestore collection
     * @param obj The document object
     * @param id The path/reference for of the document object. If no boject exists under 
     *   the provided reference, a new document object is created.
     */
    saveObject: async (collection: string, obj: DocumentData, id: string = obj.id) => {
        if ('created_at' in obj)
            obj.created_at = new Timestamp(obj.created_at.seconds, obj.created_at.nanoseconds)
        else
            obj.created_at = serverTimestamp()
        if (!('created_by' in obj))
            obj.created_by = dotProp.get(store.getState(), 'auth.user.uid', null);
        store.dispatch({
            type: actions.SAVE_OBJECT,
            payload: {object:obj, id}
        })
        try {
            await setDoc(doc(db, collection, id), obj);
            store.dispatch({ type: actions.SAVE_OBJECT_CONFIRMED })
        }
        catch (err) {
            store.dispatch(themeFunctions.error(String(err)))
            store.dispatch({ type: actions.SAVE_OBJECT_FAILED })
        }
    },
    /**
     * Delete a document object from firestore \
     *   (Changes automatically reflect on any locally mounted objects)
     * @param collection The firestore collection path.
     * @param id The path/reference of the document object.
     */
    removeObject: (collection: string, id: string) => {
        deleteDoc(doc(db, collection, id)).then(() => {
            store.dispatch({ type: actions.REMOVE_OBJECT_CONFIRMED })
        }, (err) => {
            store.dispatch(themeFunctions.error(String(err)))
            store.dispatch({ type: actions.REMOVE_OBJECT_FAILED })
        })
        store.dispatch({
            type: actions.REMOVE_OBJECT
        })
    }
}
export default functions;
