import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown'
import TOS from "../constants/documents/PRIVACY.MD";


export default function Privacy() {
    const [content, setContent]: any = useState();
    useEffect(() => {
        fetch(TOS).then(r => {
            r.text().then((t:string) => {
                setContent(t)
            })
        })
    }, [])
    return (
        <div className="post">
            <ReactMarkdown>
                {content}
            </ReactMarkdown>
        </div>
    );
};