import Pages from './pages';
import { Routes, Route } from "react-router-dom";
import draweritems from './constants/draweritems';

function PageSelector(props: { page: string }) {
  const { page } = props;
  const TargetPage = (Pages as any)[page];
  return (
    <TargetPage restrincted />
  )
}

function App() {
  var drawerPages = [];
  drawerPages = Array.isArray(draweritems) ? [].concat(...(draweritems.map((group: any) => group.items))) : draweritems['items'];
  drawerPages = drawerPages.filter(item => ('page' in item));
  return (
    <Routes>
      <Route path="/" element={<Pages.Home restrincted />} />
      <Route path="/about" element={<Pages.About />} />
      <Route path="/tos" element={<Pages.Terms />} />
      <Route path="/privacy" element={<Pages.Privacy />} />
      <Route path="/login" element={<Pages.Login layout='blank' />} />
      {drawerPages.map((item: any, i) => (
        <Route key={i} path={`/${item.slug}`} element={<PageSelector page={item.page} />} />
      ))}
      <Route path="*" element={<Pages.Page404 />} />
    </Routes>
  );
}

export default App;
