import type { Handler } from ".";

export const namespace: string = 'AUTH';

export const init_state = {
    user: undefined
}

var actions: { [key: string]: string } = {
    /**
     * Add your action types here
     */
    SET_AUTH_USER: "SET_AUTH_USER"
}
for (const key in actions) {
    actions[key] = `${namespace}.${actions[key]}`;
}

export const handlers: Handler[] = [
    /**
     * Define a handler for each action type
     *  (A handler defines the logic by which the state is manipulated)
     */
    {
        type: actions.SET_AUTH_USER,
        handler: (state, payload) => { return { ...state, user: payload } }
    }
]


/** 
 * Define dispatcher functions for each action type.
 * The dispatcher function is just a wrapper interface for 
 * a handler. It should be the only way used to manipulate the state
*/
var functions: { [key: string]: any } = {
    setAuthUser: (user: object | null) => {
        return (dispatch: Function) => {
            dispatch({
                type: actions.SET_AUTH_USER,
                payload: user,
            })
        }
    }
}
export default functions;
