import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Copyright from '../parts/Copyright';
import * as firebaseui from 'firebaseui';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebaseui/dist/firebaseui.css';
import uiConfig from '../firebase/uiConfig';
import { MutableRefObject, useEffect, useRef } from 'react';
import firebaseConfig from "../firebase/firebaseConfig";
import { useSelector } from 'react-redux';
import { User } from 'firebase/auth';
import settings from '../constants/settings';


function FirebaseLogin() {
  const mainDiv: MutableRefObject<any> = useRef(null);
  const recaptchaContainer = useRef(null);
  useEffect(() => {
    firebase.initializeApp(firebaseConfig)
    var ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
    if (mainDiv.current && !mainDiv.current.getAttribute('started')) {
      ui.start(mainDiv.current, uiConfig);
      mainDiv.current.setAttribute('started', 'true');
    }
  }, [])

  return (<div id="firebase-login-div" style={{
    margin: "auto",
    marginTop: "10vh",
    marginBottom: "10vh"
  }}>
    <div ref={mainDiv}></div>
    <div ref={recaptchaContainer}></div>
  </div>)
}

export default function Login() {
  const { user } = useSelector(({ auth }: { auth: { user: null | undefined | User } }) => auth)
  if (user) {
    window.location.replace(settings.signInSuccessURL)
  }
  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: 'url(https://source.unsplash.com/random)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minHeight: "calc( 100vh - 64px - 64px - 70px )"
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box sx={{ mt: 1 }}>
            <FirebaseLogin />
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Grid>
    </Grid>
  );
}