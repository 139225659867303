import { useDispatch, useSelector } from 'react-redux';
import {apis} from '../redux';

export default function Containered(props){
    const {theme} = useSelector(({ theme }) => theme);
    const dispatch = useDispatch();
    return (
        <div style={{border: "1px solid black", margin: 20, padding: 20}}>
            <h1>Containered Layout</h1>
            <pre>
                Current Theme is: {theme? theme : "undefined"}
            </pre>
            <button onClick={() => dispatch(apis.theme.setTheme("dark"))}>Set Theme</button>
            {props.children}
        </div>
    )
}