import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LightModeIcon from '@mui/icons-material/LightMode';
import NightlightIcon from '@mui/icons-material/Nightlight';
import themeFunctions from "../redux/theme";
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { User, signOut } from 'firebase/auth';
import { auth } from "../firebase/firebaseConfig";




function ProfileMenu(props: { openHandler: any, closeHandler: any, anchorElUser: any, user: User }) {
    const { current_theme } = useSelector(({ theme }: any) => theme)
    const mode = current_theme.palette.mode;
    const dispatch = useDispatch();
    return (
        <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={props.anchorElUser}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={Boolean(props.anchorElUser)}
            onClose={props.closeHandler}
        >
            <MenuItem onClick={props.closeHandler}>
                <ListItemIcon>
                    <AccountCircleIcon />
                </ListItemIcon>
                <ListItemText>Profile</ListItemText>
            </MenuItem>
            <MenuItem onClick={props.closeHandler}>
                <ListItemIcon>
                    <SettingsIcon />
                </ListItemIcon>
                <ListItemText>Settings</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => dispatch(themeFunctions.toggleMode()) }>
                <ListItemIcon>
                    {mode === 'dark' ? <LightModeIcon /> : <NightlightIcon />}
                </ListItemIcon>
                <ListItemText>{mode === 'dark' ? 'Light Theme' : 'Dark Theme'}</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => { props.closeHandler(); signOut(auth); }}>
                <ListItemIcon>
                    <LogoutIcon />
                </ListItemIcon>
                <ListItemText>Logout</ListItemText>
            </MenuItem>
        </Menu>
    )
}


export default function ProfileButton(props: any) {
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const { user } = useSelector(({ auth }: { auth: { user: User } }) => auth)

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    return (
        <Box {...props} >
            <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt={user.displayName || 'Profile Phonto'} src={user.photoURL || undefined} />
                </IconButton>
            </Tooltip>
            <ProfileMenu
                closeHandler={handleCloseUserMenu}
                openHandler={handleOpenUserMenu}
                anchorElUser={anchorElUser}
                user={user}
            />
        </Box>
    )
}