import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { forwardRef, useState } from 'react';
import type { logType } from '../redux/theme';
import { useSelector } from 'react-redux';

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const origin: { vertical: any, horizontal: any } = {
    vertical: 'bottom',
    horizontal: 'right',
}


function SnackerbarElement(props: { msg: { type: logType, msg: string } }) {
    const [open, setOpen] = useState(true);
    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    return (
        <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={origin}
            style={{
                position: "relative"
            }}
        >
            <Alert onClose={handleClose} severity={props.msg.type} sx={{ width: '100%' }}>
                {props.msg.msg}
            </Alert>
        </Snackbar>
    )
}



export default function Snackerbars() {
    const { logs } = useSelector(({ theme }: { theme: { logs: { type: logType, msg: string }[] } }) => theme)

    return (
        <Stack
            spacing={2}
            style={{
                position: "fixed",
                bottom: 24,
                right: 24,
                maxWidth: 'calc( 100vw - 96px)',
                zIndex: 9999
            }}
        >
            {logs.map((log, i) => <SnackerbarElement msg={log} key={i} />)}
        </Stack>
    );
}