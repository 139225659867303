import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIcon from '@mui/icons-material/Assignment';
import StorageIcon from '@mui/icons-material/Storage';
import GroupIcon from '@mui/icons-material/Group';



const draweritems = [
    {
        items: [
            {
                slug: 'dashboard',
                title: 'Dashboard',
                Icon: DashboardIcon,
                accessGroups: ['admin'], // The groups of users which can access this item. Note: All navitems require user authentication
                page: 'Home' // The page to be redered when clicking this item. Must be the same key of the default export object in /src/pages/index.tsx
            },
            {
                slug: 'database',
                title: 'Database Structure',
                Icon: StorageIcon,
                accessGroups: ['admin'],
                page: 'Database'
            },
            {
                slug: 'groups',
                title: 'User Groups',
                Icon: GroupIcon,
                accessGroups: ['admin'],
            },
            {
                slug: 'orders',
                title: 'Orders',
                Icon: ShoppingCartIcon
            },
            {
                slug: 'customers',
                title: 'Customers',
                Icon: PeopleIcon
            },
            {
                slug: 'reports',
                title: 'Reports',
                Icon: BarChartIcon
            },
            {
                slug: 'integrations',
                title: 'Integrations',
                Icon: LayersIcon
            }
        ]
    },
    {
        name: "Saved reports",
        items: [
            {
                slug: 'current-month',
                title: 'Current month',
                Icon: AssignmentIcon
            },
            {
                slug: 'last-quarter',
                title: 'Last quarter',
                Icon: AssignmentIcon
            },
            {
                slug: 'year-end-sale',
                title: 'Year-end sale',
                Icon: AssignmentIcon
            },
        ]
    }
]


// const draweritemsObj = {
//     name: "Saved reports",
//     items: [
//         {
//             slug: 'current-month',
//             title: 'Current month',
//             Icon: AssignmentIcon
//         },
//         {
//             slug: 'last-quarter',
//             title: 'Last quarter',
//             Icon: AssignmentIcon
//         },
//         {
//             slug: 'year-end-sale',
//             title: 'Year-end sale',
//             Icon: AssignmentIcon
//         },
//     ]
// }


export default draweritems;